import React, { Component} from 'react';
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';

class ModalPrintgo extends Component{

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Modal
        {...this.props}
        size="md">
        <Modal.Body>
          <div className="content-thank">
            <h3><span className="hong-sen">Chúc mừng bạn đã đăng ký thành công,</span><br />
              trở thành đối tác của Printgo!</h3>
            <p>Hãy hoàn tất hồ sơ và bắt đầu kinh doanh ngay nhé!</p>
            <div className="col-md-12">
              <a href="https://printgo.vn/" className="btn ht-btn-pop btn-tv">
                <img src="assets/img/icon-pop1.png" alt="" width={30} /> về trang chủ
              </a>

            </div>
            <p className="pop-tm">Mọi thắc mắc vui lòng liên hệ</p>
            <h4 className="hong">0333.61.3333</h4>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalPrintgo;
