import React, { Component} from 'react';


class Footer extends Component{
  render(){
    return(
      <div>
        <section className="footer-promotion">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-3  col-xs-12">
                <div className="single-policy clearfix">
                  <img src="assets/img/icon/icon-x1.png" alt="" />
                  <div className="policy-descr">
                    <h4 className="item-title">In ấn</h4>
                    <div className="item-descr">Mọi lúc, mọi nơi</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-policy clearfix">
                  <img src="assets/img/icon/icon-x2.png" alt="" />
                  <div className="policy-descr">
                    <h4 className="item-title">Chi phí</h4>
                    <div className="item-descr">Cạnh tranh nhất</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-policy clearfix">
                  <img src="assets/img/icon/icon-x3.png" alt="" />
                  <div className="policy-descr">
                    <h4 className="item-title">Thiết kế</h4>
                    <div className="item-descr">Kho thư viện phong phú</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-policy clearfix">
                  <img src="assets/img/icon/icon-x4.png" alt="" />
                  <div className="policy-descr">
                    <h4 className="item-title">Chất lượng</h4>
                    <div className="item-descr">Luôn luôn đảm bảo</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-pri">
        <div className="f-copyright text-center">
          Copyright © Công ty TNHH <span style={{textTransform: 'uppercase'}} className="green">printg<span className="gold">o</span>
          </span> Việt Nam . All Rights Reserved.
        </div>
      </section>
      </div>
    );
  }
}

export default Footer;
