import React, {Component} from 'react';
import Header from './component/Header';
import Body from './component/Body';
import Footer from './component/Footer';
import {BrowserRouter as Router} from "react-router-dom";

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <div id="siteContainer">
                        <div id="background">
                            <Header/>
                        </div>
                        <div id="siteContent" className="site-content">
                            <Body/>
                        </div>
                        <div id="siteFooter">
                            <Footer/>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
