import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import ModalPrintgo from './ModalPrintgo';

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nhaIn: {
        emailDangNhap: null, printcity: null, printemail: null, printnamefull: null,
        printnamehome: null, printpass: null, printphonenumer: null, printpassagain: null,
        printphonenumer2: null, printposition: null
      },
      nhaThietKe: {
        designAddress: null, designDateOfBird: null, designEmail: null, designFullName: null,
        designKinhNghiem: null, designName: null, designNote: null, designPhone: null,
        designCity: null, emailDangNhap: null, printpass: null, ratingCorel: null, printpassagain: null,
        ratingIllusstrator: null, ratingPhotoshop: null, urlPortfolio: null
      },
      errors: {},
      listProvince: [],
      selectedProvince: {},
      modalShow: false,
      isEnableTruePass: false,
      isLoadingShow : false
    };
    this._onProvinceSelect = this._onProvinceSelect.bind(this)
    this.onHandleNhaInChange = this.onHandleNhaInChange.bind(this);
    this.onHandleDesignChange = this.onHandleDesignChange.bind(this);
    this.onHandleNhaInSubmit = this.onHandleNhaInSubmit.bind(this);
    this._onHandleDesignSubmit = this._onHandleDesignSubmit.bind(this);

    fetch('https://web-api.printgo.vn/react/get-provice', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Accept'
      },
    }).then(function (response) {
      return response.json();
    }).then(provinces => {
      this.setState({ listProvince: provinces })
    });
  }

  setModalShow = (isShow) => {
    this.setState({
      modalShow: isShow
    })
  }

  _validEmpty = async (files) => {
    let errors = {};
    await files.map(item => {
      if (typeof item.value == "undefined" || item.value == null) {
        errors[item.name] = "Vui lòng nhập đầy đủ thông tin";
      } else if (item.name === "printemail" && typeof item.value !== "undefined") {
        if(!String(item.value).includes("@")) {
          errors[item.name] = "Vui lòng nhập Email";
        }
      }
      if ((item.name === "printphonenumer" || item.name === "printphonenumer2") && item.value !== null) {
        var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
        if (vnf_regex.test(item.value) == false) {
          errors[item.name] = "Số điện thoại sai định dạng";
        }
      }
    })
    this.setState({ errors: errors });
    return true;
  }

  handleChangeDate = date => {
    this.setState({
      nhaThietKe: {
        ...this.state.nhaThietKe,
        designDateOfBird: date
      }
    });
  };

  handleValidationNhaIn = async () => {
    let {
      printpass, printphonenumer, printphonenumer2, 
      printcity, printemail, emailDangNhap 
    } = this.state.nhaIn;
    await this._validEmpty([
      { name: "printpass", value: printpass },
      { name: "printemail", value: printemail },
      { name: "emailDangNhap", value: emailDangNhap },
      { name: "printphonenumer", value: printphonenumer },
      { name: "printphonenumer2", value: printphonenumer2 },
      { name: "printcity", value: printcity },
    ]);

    if (this.state.printpass !== this.state.printpassagain) {
      this.setState({
        errors: {
          ...this.state.errors,
          printpassagain: "Mật khẩu nhập lại chưa đúng"
        }
      });
    }

    let isValid = false;
    if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
      isValid = true;
    }

    return isValid;
  }

  onHandleNhaInChange(event) {
    const { name, value } = event.target;
    const { printpass } = this.state.nhaIn;
    if(name == 'printpassagain' && printpass == value ) {
      this.setState({
        ...this.state,
        isEnableTruePass: true
      })
    } else {
      this.setState({
        ...this.state,
        isEnableTruePass: false
      })
    }
    this.setState({
      nhaIn: {
        ...this.state.nhaIn,
        [name]: value
      },
      errors: {}
    });
  }

  onHandleDesignChange(event) {
    const { name, value } = event.target;
    const { printpass } = this.state.nhaThietKe;

    if(name == 'printpassagain' && printpass == value ) {
      this.setState({
        ...this.state,
        isEnableTruePass: true
      })
    } else {
      this.setState({
        ...this.state,
        isEnableTruePass: false
      })
    }

    this.setState({
      nhaThietKe: {
        ...this.state.nhaThietKe,
        [name]: value
      },
      errors: {}
    });
  }

  _onProvinceSelect = selectedProvince => {
    this.setState({
      selectedProvince: selectedProvince,
      nhaIn: {
        ...this.state.nhaIn,
        "printcity": selectedProvince.value
      },
      nhaThietKe: {
        ...this.state.nhaThietKe,
        "designCity": selectedProvince.value
      }
    });
  };

  _postData = (uri, data, type = 'nhaIn') => {
    this.setState({
      isLoadingShow: true
    })
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    });
  };

  onHandleNhaInSubmit = async (event) => {
    event.preventDefault(); /* chan submit*/
    const valid = this.handleValidationNhaIn();
    valid.then(isVal => {
      if (isVal == true) {
        const request = this._postData('https://cdp-api.printgo.vn/register-partner/printing', this.state.nhaIn);
        request.then(data => {
          if (data['errorCode'] === 200) {
            this.setState({
              modalShow: true,
              isLoadingShow: false
            })
          } else {
            this.setState({
              isLoadingShow: false
            })
            switch (data['errorCode']) {
              case 2:
                this.setState({
                  errors: {
                    ...this.state.errors,
                    printphonenumer2: data['message']
                  }
                });
                break;
              default:
                window.confirm("Lỗi đăng ký nhà in, vui lòng thử lại sau .!")
                break;
            }
          }
        })
      }
    })
  }

  _onHandleDesignSubmit = async () => {
    let { designCity, designAddress, designDateOfBird, designEmail,
      designFullName, printpass, designPhone, emailDangNhap, designName, printpassagain, designcity } = this.state.nhaThietKe;
    await this._validEmpty([
      { name: "designCity", value: designCity },
      { name: "designAddress", value: designAddress },
      { name: "designDateOfBird", value: designDateOfBird },
      { name: "designEmail", value: designEmail },
      { name: "designFullName", value: designFullName },
      { name: "printpass", value: printpass },   
      { name: "designPhone", value: designPhone },
      { name: "emailDangNhap", value: emailDangNhap }
    ]);
    if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
      const request = this._postData('https://cdp-api.printgo.vn/register-partner/designer', this.state.nhaThietKe, 'nhaThietKe');
      request.then(data => {
        if (data['errorCode'] === 200) {
          this.setState({ modalShow: true, isLoadingShow: false })
        } else {
          this.setState({ isLoadingShow: false })
          switch (data['errorCode']) {
            case 2:
              this.setState({
                errors: {
                  ...this.state.errors,
                  designPhone: data['message']
                }
              });
              break;
            default:
              window.confirm("Lỗi đăng ký nhà thiết kế, vui lòng thử lại sau .!")
              break;
          }
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className="content-pri">
          {
            (this.state.isLoadingShow == true) &&
            <div className="background-load">
              <div className="loader"></div>
            </div>
          }
          <div className="container">
            <div className="tabs-inforpatner">
              <ul className="nav nav-pills nav-fill navtop">
                <li className="nav-item active">
                  <Link to="#menu1" className="nav-link active" data-toggle="tab" aria-expanded="true">
                    <span>Dành cho nhà in</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#menu2" className="nav-link" data-toggle="tab">
                    <span>Dành cho nhà thiết kế</span>
                  </Link>
                </li>
              </ul>
              <div className="tab-content float-right">
                <div className="tab-pane active" role="tabpanel" id="menu1">
                  <div className="col-md-12 col-sm-12 col-xs-12 right-content">
                    <div className="form-ct">
                      <h3>thông tin đăng nhập hệ thống</h3>
                      <form onSubmit={this.onHandleNhaInSubmit}>
                        <div className="row bt-15">
                          <div className="col-md-12">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Email đăng nhập"
                              name="emailDangNhap"
                              onChange={this.onHandleNhaInChange}
                            />
                            <div className="text-in">{this.state.errors["emailDangNhap"]}</div>
                          </div>
						             </div>
                         <div className="row bt-20 bt-15">
                          <div className="col-md-6" id="show_hide_password">
                            <input
                              type="password"
                              className="form-control from-login"
                              placeholder="Mật khẩu"
                              name="printpass"
                              onChange={this.onHandleNhaInChange}
                              id="printpass"
                            />
                          <div className="text-in1">Tối thiểu 8 chữ số</div>
                          </div>
                          <div className="col-md-6" id="show_hide_password">
                            <input
                              type="password"
                              className="form-control from-login"
                              placeholder="Nhập lại mật khẩu"
                              name="printpassagain"
                              onChange={this.onHandleNhaInChange}
                              id="printpassagain"
                            />
                            {
                              (this.state.isEnableTruePass == true) &&
                              <p className="ok-pass"><i className="fa fa-check-circle"></i></p>
                            }

                            <div className="text-in1">
                              {
                                this.state.errors["printpassagain"] &&
                                <p>{this.state.errors["printpassagain"]}</p>
                                ||
                                <p>2 mật khẩu phải trùng nhau</p>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="row bt-15">
                          <div className="col-md-12">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Tên nhà in"
                              name="printnamehome"
                              onChange={this.onHandleNhaInChange}
                            />
                            <div className="text-in">{this.state.errors["printnamehome"]}</div>
                          <div className="text-in1">Lưu ý: Không thể thay đổi tên nhà in vì đi kèm với đường link hệ thống</div>
                          </div>
                        </div>
                        <div className="row bt-15">
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Số điện thoại nhà in"
                              name="printphonenumer"
                              onChange={this.onHandleNhaInChange}
                            />
                            <div className="text-in">{this.state.errors["printphonenumer"]}</div>
                          </div>
                          <div className="col-md-6">
                            <Select
                              placeholder="Tỉnh/Thành Phố"
                              onChange={this._onProvinceSelect}
                              options={this.state.listProvince}
                            />
                            <div className="text-in">{this.state.errors["printcity"]}</div>
                          </div>
                        </div>
                        <h3 className="tt-login-bt"> thông tin đại diện nhà in</h3>
                        <img src="assets/img/gach.png" alt="" />
                        <div className="row bt-15">
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Họ tên"
                              name="printnamefull"
                              onChange={this.onHandleNhaInChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Số điện thoại"
                              name="printphonenumer2"
                              onChange={this.onHandleNhaInChange}
                            />
                            <div className="text-in">{this.state.errors["printphonenumer2"]}</div>
                          </div>
                        </div>
                        <div className="row bt-15">
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Chức vụ"
                              name="printposition"
                              onChange={this.onHandleNhaInChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control from-login"
                              placeholder="Email"
                              name="printemail"
                              onChange={this.onHandleNhaInChange}
                            />
                            <div className="text-in">{this.state.errors["printemail"]}</div>
                          </div>
                        </div>
                        <div className="row bt-15">
                          <div className="col-md-12">
                            <button onClick={this.onHandleNhaInSubmit} type="submit" className="btn ht-btn-green btn-gtt" name="submit-namecard">đăng ký ngay</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" role="tabpanel" id="menu2">
                  <div className="col-md-12 col-sm-12 col-xs-12 right-content">
                    <div className="form-ct animated">
                      <h3> thông tin nhà thiết kế</h3>
                      <img src="assets/img/gach.png" alt="" />
                      <div className="row bt-15">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control from-login"
                            placeholder="Họ tên"
                            name="designFullName"
                            onChange={this.onHandleDesignChange}
                          />
                          <div className="text-in">{this.state.errors["designFullName"]}</div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group registration-date">
                            <div className="input-group registration-date-time">
                              <input onChange={this.onHandleDesignChange} className="form-control" name="designDateOfBird" id="registration-date" type="date" />
                            </div>
                          </div>
                          <div className="text-in">{this.state.errors["designDateOfBird"]}</div>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control from-login"
                            placeholder="Email"
                            name="designEmail"
                            onChange={this.onHandleDesignChange}
                          />
                          <div className="text-in">{this.state.errors["designEmail"]}</div>
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control from-login"
                            placeholder="Địa chỉ thường trú"
                            name="designAddress"
                            onChange={this.onHandleDesignChange}
                          />
                          <div className="text-in">{this.state.errors["designAddress"]}</div>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control from-login"
                            placeholder="Số điện thoại nhà thiết kế"
                            name="designPhone"
                            onChange={this.onHandleDesignChange}
                          />
                          <div className="text-in">{this.state.errors["designPhone"]}</div>
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-6 pd-30">
                          <h4 className="td-text">Trình độ</h4>
                          <div className="ai">Illusstrator
                            <fieldset className="rating">
                              <input defaultValue={5} type="radio" id="star5" name="ratingIllusstrator" onChange={this.onHandleDesignChange} />
                              <label className="full" htmlFor="star5" title="Awesome - 5 stars" />
                              <input defaultValue={4.5} type="radio" id="star4half" name="ratingIllusstrator" onChange={this.onHandleDesignChange} />
                              <label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />
                              <input defaultValue={4} type="radio" id="star4" name="ratingIllusstrator" onChange={this.onHandleDesignChange} />
                              <label className="full" htmlFor="star4" title="Pretty good - 4 stars" />
                              <input defaultValue={3.5} type="radio" id="star3half" name="ratingIllusstrator" onChange={this.onHandleDesignChange} />
                              <label className="half" htmlFor="star3half" title="Meh - 3.5 stars" />
                              <input defaultValue={3} type="radio" id="star3" name="ratingIllusstrator" onChange={this.onHandleDesignChange} />
                              <label className="full" htmlFor="star3" title="Meh - 3 stars" />
                              <input type="radio" id="star2half" name="ratingIllusstrator" defaultValue={2.5} onChange={this.onHandleDesignChange} />
                              <label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars" />
                              <input type="radio" id="star2" name="ratingIllusstrator" defaultValue={2} onChange={this.onHandleDesignChange} />
                              <label className="full" htmlFor="star2" title="Kinda bad - 2 stars" />
                              <input type="radio" id="star1half" name="ratingIllusstrator" defaultValue={1.5} onChange={this.onHandleDesignChange} />
                              <label className="half" htmlFor="star1half" title="Meh - 1.5 stars" />
                              <input type="radio" id="star1" name="ratingIllusstrator" defaultValue={1} onChange={this.onHandleDesignChange} />
                              <label className="full" htmlFor="star1" title="Sucks big time - 1 star" />
                              <input type="radio" id="starhalf" name="ratingIllusstrator" defaultValue={0.5} onChange={this.onHandleDesignChange} />
                              <label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars" />
                            </fieldset>
                          </div>
                          <div className="ai">Photoshop
                          <fieldset className="rating">
                              <input onChange={this.onHandleDesignChange} type="radio" id="star15" name="ratingPhotoshop" defaultValue={5} />
                              <label className="full" htmlFor="star15" title="Awesome - 5 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star14half" name="ratingPhotoshop" defaultValue={4.5} />
                              <label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star14" name="ratingPhotoshop" defaultValue={4} />
                              <label className="full" htmlFor="star14" title="Pretty good - 4 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star13half" name="ratingPhotoshop" defaultValue={3.5} />
                              <label className="half" htmlFor="star13half" title="Meh - 3.5 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star13" name="ratingPhotoshop" defaultValue={3} />
                              <label className="full" htmlFor="star13" title="Meh - 3 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star12half" name="ratingPhotoshop" defaultValue={2.5} />
                              <label className="half" htmlFor="star12half" title="Kinda bad - 2.5 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star12" name="ratingPhotoshop" defaultValue={2} />
                              <label className="full" htmlFor="star12" title="Kinda bad - 2 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star11half" name="ratingPhotoshop" defaultValue={1.5} />
                              <label className="half" htmlFor="star11half" title="Meh - 1.5 stars" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star11" name="ratingPhotoshop" defaultValue={1} />
                              <label className="full" htmlFor="star11" title="Sucks big time - 1 star" />
                              <input onChange={this.onHandleDesignChange} type="radio" id="star0half" name="ratingPhotoshop" defaultValue={0.5} />
                              <label className="half" htmlFor="star0half" title="Sucks big time - 0.5 stars" />
                            </fieldset>
                          </div>
                          <div className="ai">Corel <fieldset className="rating">
                            <input onChange={this.onHandleDesignChange} type="radio" id="star25" name="ratingCorel" defaultValue={5} />
                            <label className="full" htmlFor="star25" title="Awesome - 5 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star24half" name="ratingCorel" defaultValue={4.5} />
                            <label className="half" htmlFor="star24half" title="Pretty good - 4.5 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star24" name="ratingCorel" defaultValue={4} />
                            <label className="full" htmlFor="star24" title="Pretty good - 4 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star23half" name="ratingCorel" defaultValue={3.5} />
                            <label className="half" htmlFor="star23half" title="Meh - 3.5 stars" />
                            <input type="radio" id="star23" name="ratingCorel" defaultValue={3} />
                            <label className="full" htmlFor="star23" title="Meh - 3 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star22half" name="ratingCorel" defaultValue={2.5} />
                            <label className="half" htmlFor="star22half" title="Kinda bad - 2.5 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star22" name="ratingCorel" defaultValue={2} />
                            <label className="full" htmlFor="star22" title="Kinda bad - 2 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star21half" name="ratingCorel" defaultValue={1.5} />
                            <label className="half" htmlFor="star21half" title="Meh - 1.5 stars" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star21" name="ratingCorel" defaultValue={1} />
                            <label className="full" htmlFor="star21" title="Sucks big time - 1 star" />
                            <input onChange={this.onHandleDesignChange} type="radio" id="star20half" name="ratingCorel" defaultValue={0.5} />
                            <label className="half" htmlFor="star20half" title="Sucks big time - 0.5 stars" />
                          </fieldset></div>
                        </div>
                        <div className="col-md-6 pd-30">
                          <h4 className="td-text">Kinh nghiệm làm việc</h4>
                          <div className="form-check-inline">
                            <label className="customradio"><span className="radiotextsty">Dưới 1 năm</span>
                              <input onChange={this.onHandleDesignChange} type="radio" name="designKinhNghiem" value="0-1" />
                              <span className="checkmark"></span>
                            </label>
                            <label className="customradio"><span className="radiotextsty">1 - 3 năm</span>
                              <input onChange={this.onHandleDesignChange} type="radio" name="designKinhNghiem" value="1-3" />
                              <span className="checkmark"></span>
                            </label>
                            <label className="customradio"><span className="radiotextsty">Trên 3 năm</span>
                              <input onChange={this.onHandleDesignChange} type="radio" name="designKinhNghiem" value="3-100" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-12">
                          <textarea onChange={this.onHandleDesignChange} name="designNote" className="form-control mota-pop" rows={3} placeholder="Kinh nghiệp khác" defaultValue={""} />
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-12">
                          <input onChange={this.onHandleDesignChange} name="urlPortfolio" type="text" className="form-control from-login" placeholder="Link portfolio (Nếu có):" />
                        </div>
                      </div>
                      <h3 className="tt-tabs2">thông tin đăng nhập hệ thống</h3>
                      <div className="row bt-15">
                        <div className="col-md-12">
                          <input 
                            onChange={this.onHandleDesignChange} 
                            type="text" 
                            name="emailDangNhap" 
                            className="form-control from-login" 
                            placeholder="Tên đăng nhập" 
                          />
                          <div className="text-in">{this.state.errors["emailDangNhap"]}</div>
                        </div>
                        </div>
                        <div className="row bt-20 bt-15">
                         <div className="col-md-6" id="show_hide_password">
                           <input
                             type="password"
                             className="form-control from-login"
                             placeholder="Mật khẩu"
                             name="printpass"
                             onChange={this.onHandleDesignChange}
                             id="printpass"
                           />
                          <div className="text-in">{this.state.errors["printpass"]}</div>
                         <div className="text-in1">Tối thiểu 8 chữ số</div>
                         </div>
                         <div className="col-md-6" id="show_hide_password">
                           <input
                             type="password"
                             className="form-control from-login"
                             placeholder="Nhập lại mật khẩu"
                             name="printpassagain"
                             onChange={this.onHandleDesignChange}
                             id="printpassagain"
                           />
                           <div className="text-in">{this.state.errors["printpassagain"]}</div>
                           { (this.state.isEnableTruePass == true) &&
                            <p className="ok-pass"><i className="fa fa-check-circle"></i></p>
                           }
                           <div className="text-in1">
                            { this.state.errors["printpassagain"] &&
                              <p>{this.state.errors["printpassagain"]}</p>
                              ||
                              <p>2 mật khẩu phải trùng nhau</p>
                            }
                           </div>
                         </div>
                       </div>
                      <div className="row bt-15">
                        <div className="col-md-12">
                          <input onChange={this.onHandleDesignChange} name="designName" type="text" className="form-control from-login" placeholder="Tên nhà thiết kế" />
                          <div className="text-in">{this.state.errors["designName"]}</div>
                          <div className="text-in1">Lưu ý: Không thể thay đổi tên nhà in vì đi kèm với đường link hệ thống</div>
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-6">
                          <input onChange={this.onHandleDesignChange} name="designPhone" type="text" className="form-control from-login" placeholder="Số điện thoại nhà thiết kế" />
                          <div className="text-in">{this.state.errors["designPhone"]}</div>
                        </div>
                        <div className="col-md-6">
                          <Select
                            name="designcity"
                            placeholder="Chọn tỉnh/ thành phố"
                            onChange={this._onProvinceSelect}
                            options={this.state.listProvince}
                          />
                          <div className="text-in">{this.state.errors["designcity"]}</div>
                        </div>
                      </div>
                      <div className="row bt-15">
                        <div className="col-md-12">
                          <button onClick={this._onHandleDesignSubmit} type="submit" className="btn ht-btn-green btn-gtt" name="submit-design">đăng ký ngay</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="tt-contact">
                  <h4 className="title-bt">Liên hệ để được tư vấn thêm:</h4>
                  <p>Hotline: <a to="tel:1900633313"><span className="gold-bold">1900.633.313</span></a> - Email:
                  <a href="mailto:doitac@printgo.vn"><span className="green-light"> doitac@printgo.vn</span></a> - Website:
                  <a href="https://printgo.vn/" target="_blank"><span className="green-light"> https://printgo.vn </span></a>
                  - Fanpage: <a href="https://www.facebook.com/Printgo.vn" target="_blank">
                    <span className="green-light"> /Printgo.vn</span></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalPrintgo
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)} 
        />
      </div>
    );
  }
}

export default Body;
