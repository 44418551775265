import React, { Component} from 'react';
import { Link } from "react-router-dom";

class Header extends Component{
  render(){
    return(
      <div>
        <div id="background">
         <header id="siteHeader" className="site-header fix-on-top">
           <div className="container">
             <div className="row">
               <div className="header-top">
                 <div className="col-md-4 col-lg-4 col-xs-12">
                   <div className="logo">
                     <Link to="">
                       <img src="assets/img/logo.png" />
                     </Link>
                   </div>
                 </div>
                 <div className="col-md-8 col-lg-8 col-xs-12">
                   <h4 className="name-ct">công ty tnhh printgo việt nam</h4>
                   <div className="bt-contact">
                     <div className="zone zone1-contact">
                       <a href="tel:1900633333"><span className="gold-bold">0333.61.3333</span></a>
                     </div>
                     <div className="zone zone2-contact">
                       <a href="mailto:doitac@printgo.vn" target="_blank">
                         <span className="green-light">
                           <img src="assets/img/i-1.png" /> doitac@printgo.vn
                         </span>
                       </a>
                     </div>
                     <div className="zone zone3-contact">
                       <a href="https://printgo.vn/" target="_blank">
                         <span className="green-light"><img src="assets/img/i-1.png" />  https://printgo.vn</span>
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </header>
         <div className="container">
           <div className="text-head">
             <div className="head-top">
               <h3> đăng ký để trở thành đối tác <span style={{textTransform: 'uppercase'}} className="green">printg<span className="gold">o</span>
                 </span></h3>
               <img src="assets/img/gach.png" />
             </div>
             <p>Cảm ơn đối tác đã lựa chọn Printgo để đồng hành cùng phát triển, đẩy mạnh doanh số và đem lại giá trị cho khách hàng!<br /><br />
               Vui lòng hoàn tất thông tin để tạo tài khoản đăng nhập trên hệ thống của chúng tôi!</p>
           </div>
         </div>
       </div>
      </div>
    );
  }
}

export default Header;
